<template>
  <div>
    <div v-if="!loading">
      <SlideFadeTransition>
        <router-view />
      </SlideFadeTransition>
    </div>

    <!-- loader -->
    <div v-else>
      <FullLoader />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SlideFadeTransition from '../../Transitions/SlideFadeTransition'
import FullLoader from '@/components/MainContent/FullLoader'

const InsightsModule = createNamespacedHelpers('insights')
const LocationModule = createNamespacedHelpers('location')
const DataCoreModule = createNamespacedHelpers('dataCore')
const GroupModule = createNamespacedHelpers('group')
const SurveyModule = createNamespacedHelpers('survey')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'InsightsViews',
  components: {
    SlideFadeTransition,
    FullLoader,
  },
  data: () => ({
    loading: true,
  }),
  async mounted() {
    await Promise.all([
      this.fetchCategories(),
      this.getUserLocationsByActiveCompany(),
      this.fetchGroups({
        memberType: 'location',
        companyIds: this.selectActiveCompanyIds,
      }),
      this.activeCompany._id === 'ALL_COMPANIES'
        ? this.fetchMultipleCompanySources({ insightsOnly: true })
        : this.getSources(),
    ])
    this.loading = false
  },
  computed: {
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds']),
    ...CompanyModule.mapGetters(['activeCompany', 'selectInsightsCompanies']),
  },
  methods: {
    ...InsightsModule.mapActions(['fetchCategories']),
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    ...GroupModule.mapActions(['fetchGroups']),
    ...SurveyModule.mapActions(['getSources', 'fetchMultipleCompanySources']),
  },
}
</script>
