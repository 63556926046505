<template>
  <MainContent>
    <div class="container-fluid" :key="activeCompany._id" v-if="activeCompany">
      <div class="header mb-2">
        <div class="header-body">
          <div class="row align-items-center">
            <!-- title -->
            <div class="col">
              <h1 class="header-title">Insights</h1>
            </div>
          </div>
        </div>
      </div>
      <InsightsViews v-if="insightsEnabled" />
      <InsightsOffstate v-else />
    </div>
  </MainContent>
</template>

<script>
import { trackEvent } from '@/lib/analytics'
import InsightsViews from '../components/Modules/Insights/InsightsViews'
import InsightsOffstate from '../components/Modules/Insights/InsightsOffstate'
import MainContent from '../components/MainContent/MainContent'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'InsightsPage',
  components: {
    MainContent,
    InsightsViews,
    InsightsOffstate,
  },
  async mounted() {
    trackEvent(this.$intercom, 'Insights')
  },
  computed: {
    ...CompanyModule.mapGetters(['activeCompany', 'selectInsightsCompanies']),
    insightsEnabled() {
      return this.activeCompany._id === 'ALL_COMPANIES' 
        ? !!this.selectInsightsCompanies?.length
        : this.activeCompany.insightsEnabled
    },
  },
}
</script>
